<template>
  <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :search="search"
            class="elevation-1"
            >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
              ></v-text-field>
            </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="addCommand(item)"
            >
                mdi-cog
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
            </template>
            <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="initialize"
            >
                Reset
            </v-btn>
          </template> 
        <template v-slot:item.expirationDate="{item}">
          {{getDate(item)}}
        </template>
        <template v-slot:item.created="{item}">
          {{getDateTime(item)}}
        </template>
        <template v-slot:item.userid="{item}">
          {{getUserName(item.userid)}}
        </template>
        <template v-slot:item.devicetypeid="{item}">
          {{getDevTypeName(item.devicetypeid)}}
        </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props:{
        typeList: {
            type:Array
            ,default: () =>  []
        }
        ,userList:{
            type:Array
            ,default:()=>[]
        }
        ,reload:{
          type:Boolean
          ,default: false
        }
    },
    data:()=>({
        headers:[
          {
            text: 'Id',
            align: 'start',
            value: 'id',
          },
          { text: 'IMEI', value: 'uniqueid' },
          { text: 'Nombre', value: 'name' },
          { text: 'Icon', value: 'icon' },
          { text: 'Phone', value: 'phone' },
          { text: 'Expira', value: 'expirationDate' },
          { text: 'Tipo', value: 'devicetypeid' },
          { text: 'User', value: 'userid' },
          { text: 'Active', value: 'active' },
          { text: 'Creado', value: 'created' },
          { text: 'Actions',value: 'actions'}
        ],
        items:[],
        loading:true,
        search:'',
    }),
    mounted(){
      this.loadDevices();
    },
    watch:{
      reload(newVal){
        if(newVal){
            console.log('reloading device Table');
            this.loadDevices();
            this.$emit('reloaded');
          }
        }
    },
    methods:{
      ...mapActions(['getAllDevices']),
      loadDevices(){
          this.loading = true;
            this.getAllDevices().then((res) => {
                this.items = res.data.data;

                //console.log(this.items);
                this.loading = false;
            })
            .catch((err)=>{
                console.log(err);
            });
      },
      editItem(item){
        //console.log(item)
        this.$emit('editItem',item);
      },
      deleteItem(item){
        //console.log(item);
        this.$emit('deleteItem',item);
      },
      getDate(item){
          return this.$func.getDateString(item.expirationDate,'');
      },
      getDateTime(item){
          return this.$func.getFullDateString(item.created,'');
      },
      getDevTypeName(id){
        return this.typeList.find(e => e.id == id).name;
      },
      getUserName(id){
        return this.userList.find(e => e.id == id).name;
      }
      ,addCommand(item){
        this.$emit('editCommand',item);
      }
    }
}
</script>

<style>

</style>